<template>
  <div class="w-full lg:px-12">
    <p class="title">Central de Documentos</p>
    <div class="mt-6">
      <table class="relative">
        <div class="backdrop" v-show="loadingPage"><div class="fas fa-dollar-sign spin"></div></div>
        <tr>
          <td @click="showDocEmpresa = !showDocEmpresa">
            <span><i :class="`fas fa-${showDocEmpresa ? 'minus' : 'plus'}`"></i></span>
            Documentos da empresa
          </td>
          <transition v-if="showDocEmpresa" name="fade">
            <div>
              <document v-for="doc of businessDocs" :key="`bus_${doc.tipoDocumentoId}`" :doc="doc" type="business" @reloadDocs="loadBusinessDocs"/>
            </div>
          </transition>
        </tr>
        <tr>
          <td @click="showDocSocilitante = !showDocSocilitante">
            <span><i :class="`fas fa-${showDocSocilitante ? 'minus' : 'plus'}`"></i></span>
            Documentos do solicitante
          </td>
          <transition v-if="showDocSocilitante" name="fade">
            <div v-for="requester of requesterDocs" :key="requester.socio.cpf">
              <div class="flex my-2 items-center justify-between">
                <p class="partner_name">{{requester.socio.nomeCompleto}}</p>
                <p class="hint"><span class="text-red-600">* </span>Envio obrigatório</p>
              </div>
              <document v-for="doc of requester.statusDocumentos.status" :key="`req_${doc.tipoDocumentoId}`" :doc="doc" :socioId="requester.socio.id" type="requester" @reloadDocs="loadPartnerDocs"/>
            </div>
          </transition>
        </tr>
        <tr>
          <td @click="showDocSocios = !showDocSocios">
            <span><i :class="`fas fa-${showDocSocios ? 'minus' : 'plus'}`"></i></span>
            Documentos do sócios
          </td>
          <transition-group v-if="showDocSocios" name="fade">
            <div v-for="partner of partnerDocs" :key="partner.socio.cpf">
              <div class="flex my-2 items-center">
                <p class="partner_name">{{partner.socio.nomeCompleto}}</p>
                <p class="hint"><span class="text-red-600">* </span>Envio obrigatório</p>
              </div>
              <document v-for="doc of partner.statusDocumentos.status" :key="`par_${doc.tipoDocumentoId}`" :doc="doc" :socioId="partner.socio.id" type="partner" @reloadDocs="loadPartnerDocs"/>
            </div>
          </transition-group>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import Document from '@/components/Document/Document'

import Documents from '@/services/Business/Documents'

export default {
  name: 'documents-central',
  components: { Document },
  data () {
    return {
      loadingPage: true,

      showDocSocilitante: false,
      showDocEmpresa: false,
      showDocSocios: false,

      requesterDocs: [],
      partnerDocs: [],
      businessDocs: []
    }
  },
  methods: {
    async loadPartnerDocs () {
      try {
        const partnerDocs = (await Documents.getPartnerDocs()).data.data
        const requesterIndex = partnerDocs.findIndex(doc => doc.socio.tipoSocio === 'socio-solicitante')
        this.requesterDocs = partnerDocs.splice(requesterIndex, 1)
        this.partnerDocs = partnerDocs
      } catch (error) {
        console.error(error)
      }
    },
    async loadBusinessDocs () {
      try {
        const businessDocs = (await Documents.getBusinessDocs()).data.data
        this.businessDocs = businessDocs.status
      } catch (error) {
        console.error(error)
      } finally {
        this.loadingPage = false
      }
    }
  },
  mounted () {
    this.loadPartnerDocs()
    this.loadBusinessDocs()
  }
}
</script>

<style lang="sass" scoped>

.title
  @apply font-bold text-xl text-primary text-center mt-4 mb-8
  @screen lg
    @apply text-2xl

// Tabela
table
  @apply w-full border-none

table tr
  @apply py-1 w-full
  &:focus
    @apply outline-none

table td
  @apply border-b border-primary w-full flex items-center py-2 opacity-75 text-sm text-primary font-semibold uppercase cursor-pointer select-none
  @screen lg
    @apply text-xl

table td span
  @apply text-sm py-1 mr-3
  @screen lg
    @apply mr-6

.partner_name
  @apply font-bold opacity-75
  @screen lg
    @apply w-1/2

.hint
  @apply text-xs text-right
  @screen lg
    @apply w-1/2

.backdrop
  @apply absolute w-full h-full bg-white text-center text-4xl text-primary
  z-index: 60
  opacity: 0.9

.spin
  animation: rotatey 2s linear infinite

@keyframes rotatey
  from
    transform: rotateY(0deg)
  to
    transform: rotateY(360deg)

</style>
